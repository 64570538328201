import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction, DocumentSnapshot } from '@angular/fire/firestore';
import { Job } from '../models/job';
import { Observable, of } from 'rxjs';
import { Action } from 'rxjs/internal/scheduler/Action';

@Injectable({
  providedIn: 'root'
})
export class JobsService {

  constructor(
    private firestore: AngularFirestore
  ) { }

  JOBS_COLLECTION : string = "jobs";
  createJob(job) {
    return this.firestore.collection(this.JOBS_COLLECTION).add(job);
  }

  readAllJobs() : Observable<DocumentChangeAction<any>[]> {
    return this.firestore.collection(this.JOBS_COLLECTION).snapshotChanges();
  }


  getJobetails(jobId : string){
    return this.firestore.collection(this.JOBS_COLLECTION).doc(jobId).snapshotChanges();
  }

  updateJob(recordID,job){
    console.log("for record id ", recordID);
    console.log("for job ", job);
    return this.firestore.collection(this.JOBS_COLLECTION).doc(recordID).set(job);
  }

  save(job){
    console.log("for job ", job);
    return this.firestore.collection(this.JOBS_COLLECTION).add(job);
  }


  searchUsers(searchValue) : Observable<DocumentChangeAction<any>[]> {
    // return this.firestore.collection(this.JOBS_COLLECTION,ref =>
    //   ref.where('resposibilities', 'array-contains', searchValue))
    //   .snapshotChanges();
    console.log("searchValue", searchValue);


    // start with
    // return this.firestore.collection(this.JOBS_COLLECTION,ref => ref.where('jobTitle', '>=', searchValue)
    //   .where('jobTitle', '<=', searchValue + '\uf8ff'))
    //   .snapshotChanges();


    // ends with
    return this.firestore.collection(this.JOBS_COLLECTION,ref => ref.where('jobTitle', '>=',  '\uf8ff' + searchValue  + '\uf8ff')
      .where('jobTitle', '<=','\uf8ff' +  searchValue  + '\uf8ff'))
      .snapshotChanges()


    // return this.firestore.collection(this.JOBS_COLLECTION,ref => ref
    //   .endAt(searchValue))
    //   .snapshotChanges()
  }

}
