<div class="container">
    <!-- Resources -->
      <div class="cover-content wrapper text-center">
        <a class="logo" routerLink="/home">
          <img src="assets/img/base/logo.png"/>
        </a>
        <h1>
          <span class="fadeIn">
            <b>  People.</b>&nbsp;<u>Product.</u>&nbsp;<i>Technology.</i>
          
          </span>
          <span class="reveal"></span>
        </h1>
      </div>
      <div class="wrapper">
        <div class="row">
          <div class="col-sm-12 text-center">
            <!-- <img src="assets/img/base/labcase.png"> -->
            <p>A compact team of technology people building next generation product.</p>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-sm-4">
            <h4>People.</h4>
            <p>We're in process of building a team of technology expert and problem solver.</p>
          </div>
          <div class="col-sm-4">
            <h4>Product.</h4>
            <p>We're in progress of building a stealth product which will re-define patient engagement.</p>
          </div>
          <div class="col-sm-4">
            <h4>Technology.</h4>
            <p>We're building our product which uses cutting edge technologies.</p>
          </div>
        </div>
      </div>
  </div>