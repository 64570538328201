import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private metaTagService: Meta  
    ) { }

  ngOnInit() {
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'Boundedline Technologies, Pune, Baner, Full stack, product development, Boundeline Tech, product owner'}
    );
  }

}
