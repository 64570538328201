<section class="featured-jobs">
    <div class="container">
        <div class="row" *ngIf="authService.isBlTechUser">
            <div class="formGroup">
                <a class="btn btn-primary" routerLink="./add">
                  <i class="fab fa-plus"></i>Add Job
                </a>
            </div>
        </div>
		<div class="center-block"><h1>Opportunities at Boundedline Technologies</h1></div>
        <div class="row">
            <div class="col-sm-12">
                <ul>
                    <li>Please send your resumes to hr[at]boundedlinetechnologies[dot]com</li>
                    <li>Please use position listed below in subject line while sending resume.</li>
                </ul>
                <ngb-accordion #acc="ngbAccordion">
                    <ngb-panel title="{{ individualJob.jobTitle}} - Minimum of {{ individualJob.experience}} experience {{ individualJob.active ? '' : ' - In active'}}"  *ngFor="let individualJob of job">
                        <ng-template ngbPanelContent>
                            <h3>Skills</h3>
                            <ul>
                                <li *ngFor="let skill of individualJob.skills">{{skill}}</li>
                            </ul>
                            <h3>Responsibility</h3>
                            <ul>
                                <li *ngFor="let responsibility of individualJob.resposibilities">{{responsibility}}</li>
                            </ul>

                            <div class="row" *ngIf="authService.isBlTechUser">
                                <div class="col-lg-6">
                                    <a class="btn btn-danger text-white" (click)="deleteJob(individualJob.id)">
                                    <i class="fab fa-plus"></i>Delete Job
                                    </a>
                                </div>
                                <div class="col-lg-6">
                                    <a class="btn btn-primary float-right" routerLink="./edit/{{individualJob.id}}">
                                    <i class="fab fa-plus"></i>Edit Job
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <!--
                    <tr>
                    <td>Senior Full stack developer</td>
                    <td>9+ years</td>
                    <td>
                        <p>Experience in Jsp, Angular 8, Bootstrap</p>
                        <p>Experience in Backend REST Web Services, APIs, Modern Database</p>
                        <p>Experience in Java/J2EE, spring, hibernate, </p>
                        <p>Experience in with JIRA </p>
                        <p>Experience in core JAVA, Spring framework,Hibernate.</p>
                        <p>Experience in RESTful API development and API query language</p>
                        <p>Experience in test driven development and corresponding tools like Junit.</p>
                        <p>Experience in UML based modeling and design.</p>
                        <p>Experience in npm, web based technologies HTML/CSS.</p>
                        <p>Experience in any of CI/CD tools and frameworks.</p>
                        <p>Experience in using Linux</p>
                        <p>Experience in team environment & leading team.</p>
                    </td>
                    <td>
                        <p>Design, analyze, and develop innovative visualizations to meets the products reporting needs.</p>
                        <p>Proficient in REST web services, Data structures.</p>
                        <p>Integration of user-facing elements with server-side logic</p>
                        <p>Building reusable code and libraries for future use</p>
                        <p>Optimization of the application for maximum speed and scalability</p>
                        <p>Implementation of security and data protection</p>
                        <p>Design and implementation of data storage solutions</p>
                        <p>Co-ordinate with rest of full stack developer in implementation of features</p>
                    </td>
                </tr>
                <tr>
                    <td>Full stack developer</td>
                    <td>5+ years</td>
                    <td>
                        <p>Experience in Jsp, Angular 8, Bootstrap</p>
                        <p>Experience in Backend REST Web Services, APIs, Modern Database</p>
                        <p>Experience in Java/J2EE, spring, hibernate, </p>
                        <p>Experience in with JIRA </p>
                        <p>Experience in core JAVA, Spring framework,Hibernate.</p>
                        <p>Experience in RESTful API development and API query language GraphQL</p>
                        <p>Experience in test driven development and corresponding tools like Junit.</p>
                        <p>Experience in UML based modeling and design.</p>
                        <p>Experience in npm, web based technologies HTML/CSS.</p>
                        <p>Experience in CI/CD</p>
                        <p>Experience in using Linux</p>
                    </td>
                    <td>
                        <p>Design, analyze, and develop innovative visualizations to meets the client reporting needs.</p>
                        <p>Proficient in REST web services, Data structures.</p>
                        <p>Integration of user-facing elements developed by front-end developers with server-side logic</p>
                        <p>Building reusable code and libraries for future use</p>
                        <p>Optimization of the application for maximum speed and scalability</p>
                        <p>Implementation of security and data protection</p>
                        <p>Design and implementation of data storage solutions </p>
                    </td>
                </tr>
                <tr>
                    <td>Senior Mobile Developer</td>
                    <td>7+ years</td>
                    <td>
                        <p>Experience in Android app development.</p>
                        <p>Experience with Android SDK, Java, Kotlinand multiple versions of Android.</p>
                        <p>Experience with Android framework, as well as offline storage, threading, and performance tuning.</p>
                        <p>Experience with JSON concepts and REST APIs using okhttp.</p>
                        <p>Experience with Firebase suite such as Crashlytics.</p>
                        <p>Experience writing unit tests using junit.</p>
                        <p>Experience with app resources like Drawable, Layout, Animation, mipmap, Interpolator.</p>
                        <p>Experience with ORM tool.</p>
                        <p>Android Application Binary Interfaces (ABIs).</p>
                        <p>Localization.</p>
                        <p>Experience in with JIRA</p>
                    </td>
                    <td>
                        <p>Worked on native apps development</p>
                        <p>Worked on apps using REST web services</p>
                        <p>Worked on apps compatible with multiple screen resolution</p>
                        <p>Should have understanding of software development life-cycle and best practices</p>
                        <p>Convert raw images and layouts from a graphic designer into Android themes.</p>
                        <p>Determine appropriate architecture, and other technical solutions, and make relevant recommendations to prdouct.</p>
                        <p>Communicate to the Project Manager with efficiency and accuracy any progress andor delays. </p>
                        <p>Engage in out-of-the-box thinking to provide high value-of-service to product.</p>
                        <p>Develop innovative, reusable Android-based tools for activism and community building.</p>
                    </td>
                </tr>
                <tr>
                    <td>Mobile Developer</td>
                    <td>3+ years</td>
                    <td>
                        <p>Experience in iOS development using swift.</p>
                        <p>Experience in software development using one or more general purpose programming languages.</p>
                        <p>Experience working with iOS specific mobile applications from ground up.</p>
                        <p>Experience in with JIRA </p>
                    </td>
                    <td>
                        <p>Worked on native apps development</p>
                        <p>Worked on apps using REST and SOAP web services</p>
                        <p>Worked on apps compatible with multiple screen resolution</p>
                        <p>Should have understanding of software development life-cycle and best practices</p>
                        <p>Determine appropriate architecture, and other technical solutions, and make relevant recommendations to product.</p>
                        <p>Communicate to the Project Manager with efficiency and accuracy any progress andor delays. </p>
                        <p>Engage in out-of-the-box thinking to provide high value-of-service to product.</p>
                    </td>
                </tr>
                <tr>
                    <td>Senior Automation Engineer</td>
                    <td>7+ years</td>
                    <td>
                        <p>Experience in selenium with Java</p>
                        <p>Experience in BDD / Behavior-Driven Development framework like Cucumber, JBehave.</p>
                        <p>Experience in Agile Methodologies</p>
                        <p>Experience in Junit are highly preferred.</p>
                        <p>Expertise in debugging code and exception handling</p>
                        <p>Good knowledge in API calls through jar and capture response</p>
                        <p>Experience in implementation of Automation Test plan/ Strategy</p>
                        <p>Experience in Knowledge in SQL is added advantage</p>
                        <p>Experience with any of critical tools such as cucumber, cypress, selenium,Espresso, Jmeter, protractor, appium, JIRA</p>
                    </td>
                    <td>
                        <p>Worked on automation development project</p>
                        <p>Worked on consuming REST  web services</p>
                        <p>Communicate to the Project Manager with efficiency and accuracy any progress and delays.</p>
                    </td>
                </tr>
                <tr>
                    <td>Senior Backend Developer</td>
                    <td>8+ years</td>
                    <td>
                        <p>Experience in Backend REST Web Services, APIs, Modern Database</p>
                        <p>Experience in Java/J2EE, spring, hibernate, </p>
                        <p>Experience in with JIRA </p>
                        <p>Experience in core JAVA, Spring framework,Hibernate.</p>
                        <p>Experience in RESTful API development and API query language</p>
                        <p>Experience in test driven development and corresponding tools like Junit.</p>
                        <p>Experience in UML based modeling and design.</p>
                        <p>Experience in any of CI/CD tools and frameworks.</p>
                        <p>Experience in using Linux</p>
                        <p>Experience in team environment & leading team.</p>
                        <p>Experience in buidling 3rd party integration solution using tool such as apache kafka</p>
                        <p>Thorough understanding of AWS cloud architecture.</p>
                        <p>Experience in building machine learning based solution</p>
                    </td>
                    <td>
                        <p>Design, analyze, and develop innovative visualizations to product roadmap.</p>
                        <p>Proficient in REST web services, Data structures.</p>
                        <p>Building reusable code and libraries for future use</p>
                        <p>Optimization of the application for maximum speed and scalability</p>
                        <p>Implementation of security and data protection</p>
                        <p>Design and implementation of data storage solutions</p>
                        <p>Co-ordinate with rest of full stack developer in implementation of features</p>
                    </td>
                </tr> -->
            </div>
        </div>
	</div>
</section>
