export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCB04TKq5EonmB84g3kx-WkkuUWvCdu26Y",
    authDomain: "boundedline-76c55.firebaseapp.com",
    projectId: "boundedline-76c55",
    storageBucket: "boundedline-76c55.appspot.com",
    messagingSenderId: "833226778981",
    appId: "1:833226778981:web:2f2ac75241ef158877198c",
    measurementId: "G-H17L16VER5"
  }
};
